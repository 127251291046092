import React from "react";
import { useSingleState } from "../../hooks.js/useSingleState";

const VerifyEmail = () => {
  const loading = useSingleState(true);
  const successful = useSingleState(false);
  const colorDeterminant = successful.use ? "green" : "red";
  const header = useSingleState("Oooppppss......");
  const message = useSingleState("Your Email was not verified");
  return (
    <div>
      <div className="bg-white w-screen md:h-screen">
        {/* {loading.use && <LoaderOverlay />} */}
        <div className="container flex flex-col">
          <nav className="flex items-center text-white pt-5">
            <h1 className="text-3xl force-black font-medium">Dizbuzz</h1>
          </nav>
          <div className="flex justify-center mt-32">
            <div className="shadow-lg w-84 bg-white p-6 text-center">
              <h6 className={`text-2xl text-${colorDeterminant}-500`}>
                {header.use}
              </h6>
              <p className="mt-3">{message.use}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
