import axios from "axios";

import { appConfig } from "./constants.js";

import { storageContainsToken, getTokenFromStorage } from "./functions";

function apiWithoutToken() {
  const config = { baseURL: appConfig.base_url };

  const instance = axios.create(config);

  return instance;
}

function apiWithToken() {
  const config = { baseURL: appConfig.base_url };

  if (storageContainsToken()) {
    const token = getTokenFromStorage();
    config.headers = { Authorization: `Bearer ${token}` };
  }

  const instance = axios.create(config);

  return instance;
}

export const register = data => {
  return apiWithoutToken().post("/auth/signup", data);
};

export const login = data => {
  return apiWithoutToken().post("/auth/login", data);
};

// All get requests

export const getLoggedInUser = () => {
  return apiWithToken().get("/auth/logged_in_user");
};

export const getCustomers = url => {
  return apiWithToken().get(url);
};

export const globalGetApi = url => {
  return apiWithToken().get(url);
};

export const getCustomerProfile = () => {
  return apiWithToken().get("/borrower");
};

export const getAllLoanProducts = () => {
  return apiWithToken().get("/loan/loan-product");
};

export const getSingleLoanProduct = id => {
  return apiWithToken().get(`loan/loan-product/${id}`);
};

export const getSingleESignature = id => {
  return apiWithToken().get(`/e_signatures/documents/${id}`);
};

export const getSingleWorkingStatusById = id => {
  return apiWithToken().get(`/borrower/staff/working-status/${id}`);
};

export const getACustomer = id => {
  return apiWithToken().get(`/customers/${id}`);
};

export const getBanks = () => {
  return apiWithToken().get("/accounts/banks");
};

export const getAllTemplates = () => {
  return apiWithToken().get("/templates/email");
};

export const getEmailVariables = () => {
  return apiWithToken().get("/templates/email/variables");
};

export const getAllStaffs = () => {
  return apiWithToken().get("/staffs");
};

export const getAllBorrowersProfileStaff = () => {
  return apiWithToken().get("/borrower/staff/borrower");
};

export const getAllLoansCustomer = () => {
  return apiWithToken().get("/loan");
};

export const getAllRepaymentsCustomer = () => {
  return apiWithToken().get("/repayments");
};

export const getAllRepaymentsStaff = () => {
  return apiWithToken().get("/repayments/staff/repayment");
};

export const getAllLoansStaff = () => {
  return apiWithToken().get("/loan/staff/loan");
};

export const addDebitCard = () => {
  return apiWithToken().get("/repayments/add-card");
};

export const getAllCollaterals = () => {
  return apiWithToken().get("/collateral/staff/collateral");
};

export const getDisburseableLoansStaff = () => {
  return apiWithToken().get("loan/staff/loan?status=disbursable");
};

export const getDisbursedLoansStaff = () => {
  return apiWithToken().get("loan/staff/loan?status=disbursed");
};

export const getBorrowerById = id => {
  return apiWithToken().get(`/borrower/staff/${id}`);
};

export const getLoanComments = id => {
  return apiWithToken().get(`/loan/comment/${id}`);
};

export const getBranchesStaff = () => {
  return apiWithToken().get("/branch");
};

export const getLoanRepaymentStatus = id => {
  return apiWithToken().get(`/repayments/due/${id}`);
};

export const getPreviousLoanState = id => {
  return apiWithToken().get(`/loan/staff/loan/pre-state/${id}`);
};

export const getAllPermissions = () => {
  return apiWithToken().get("/permissions");
};

export const getRoles = () => {
  return apiWithToken().get("/roles");
};

export const getAllLoanOfficers = () => {
  return apiWithToken().get("/staffs/loan-officer");
};

export const getInvestorProfiles = () => {
  return apiWithToken().get("/investor_profiles/staffs");
};

export const getAssetTypes = () => {
  return apiWithToken().get("/assets/asset-types");
};

export const getIncomeType = () => {
  return apiWithToken().get("/other_incomes/other-income-types");
};

export const getOtherIncome = () => {
  return apiWithToken().get("/other_incomes");
};

export const getAssets = () => {
  return apiWithToken().get("/assets");
};

export const getCurrentRepaymentStaff = id => {
  return apiWithToken().get(`/repayments/due/${id}?currentOnly=true`);
};

export const getBorrowerGroupById = id => {
  return apiWithToken().get(`/borrower/staff/group-borrower/${id}`);
};

export const getGuarantorsStaff = () => {
  return apiWithToken().get(`/guarantor/staff_access`);
};

export const getSavingsProductFees = () => {
  return apiWithToken().get(`/savings_fees`);
};

export const getInvestmentFees = () => {
  return apiWithToken().get(`/investments_fees`);
};

export const getLoanFees = () => {
  return apiWithToken().get(`/loan_fees`);
};

export const getMissedLoans = () => {
  return apiWithToken().get(`/loan/staff/loan/missed-loan`);
};

export const getPrincipalOutstanding = () => {
  return apiWithToken().get(`/loan/filter/loan-principal-outstanding`);
};

export const getMissedRepayments = value => {
  return apiWithToken().get(`/loan/filter/missed-repayment?days=${value}`);
};

export const getNoRepaymentLoans = value => {
  return apiWithToken().get(`/loan/filter/no-repayment?days=${value}`);
};

export const getLoansInArrears = value => {
  return apiWithToken().get(`/loan/filter/loan-in-arrears?days=${value}`);
};

export const getPastmaturityLoans = value => {
  return apiWithToken().get(`/loan/filter/past-maturity?days=${value}`);
};

export const getPaidLoans = () => {
  return apiWithToken().get(`/loan/staff/loan/paid-loan`);
};

export const getBorrowerGroups = () => {
  return apiWithToken().get(`/borrower/staff/group-borrower`);
};

export const loanRestructuringRequest = url => {
  return apiWithToken().get(url);
};

export const getLoanSchedule = id => {
  return apiWithToken().get(`/repayments/due/${id}`);
};

export const getLoanScheduleInitial = id => {
  return apiWithToken().get(`/repayments/schedule/${id}`);
};

export const getCreditReport = id => {
  return apiWithToken().get(`/borrower/${id}/remita-report`);
};

export const getCollateralById = id => {
  return apiWithToken().get(`/collateral/staff/collateral/${id}`);
};

export const getSingleBorrowerLoan = id => {
  return apiWithToken().get(`/loan/staff/loan/${id}`);
};

export const getCreditReportSummary = id => {
  return apiWithToken().get(`/borrower/${id}/credit-report-summary`);
};

// Report endpoints

export const getBorrowerReport = (start, end) => {
  return apiWithToken().get(
    `/reports/borrowers?startDate=${start}&endDate=${end}`
  );
};
export const getCollectionsReport = () => {
  return apiWithToken().get(`/reports/collection`);
};

export const getDisbursementReport = (start, end) => {
  return apiWithToken().get(
    `/reports/disbursements?startDate=${start}&endDate=${end}`
  );
};

export const getLoanProductReport = (start, end) => {
  return apiWithToken().get(
    `/reports/loan_products?startDate=${start}&endDate=${end}`
  );
};

export const getLoanOfficerReport = (start, end) => {
  return apiWithToken().get(
    `/reports/loan_officer?startDate=${start}&endDate=${end}`
  );
};

export const getLoanReport = (start, end) => {
  return apiWithToken().get(`/reports/loans?startDate=${start}&endDate=${end}`);
};

export const getSavingsReport = (start, end) => {
  return apiWithToken().get(
    `/savings_reports?startDate=${start}&endDate=${end}`
  );
};

export const getSavingsProductReport = (start, end) => {
  return apiWithToken().get(
    `/savings_reports/products?startDate=${start}&endDate=${end}`
  );
};

export const getOutstandingReport = (start, end) => {
  return apiWithToken().get(
    `/reports/outstandings?startDate=${start}&endDate=${end}`
  );
};

export const getFeesReport = (start, end) => {
  return apiWithToken().get(`/reports/fees?startDate=${start}&endDate=${end}`);
};

export const getCollectorReport = () => {
  return apiWithToken().get(`reports/collector`);
};

export const getExpense = () => {
  return apiWithToken().get(`/expense`);
};

export const getAllSavingsProfile = () => {
  return apiWithToken().get(`/savings_profiles/staffs`);
};

export const getAllInvestorProfile = () => {
  return apiWithToken().get(`/investor_profiles/staffs`);
};

export const getSavingsProduct = () => {
  return apiWithToken().get(`/savings_products`);
};

export const getSavingsTransactions = () => {
  return apiWithToken().get(`/savings_transactions/staff_routes`);
};

export const getInvestorTransactions = () => {
  return apiWithToken().get(`/investor_transaction/staff/transaction`);
};

export const getSavingsAccounts = () => {
  return apiWithToken().get(`/savings_wallets/staffs`);
};

export const getInvestorAccounts = () => {
  return apiWithToken().get(`/investor_accounts/staffs`);
};

export const getLoanInvestmentProduct = () => {
  return apiWithToken().get(`/loan_investor/staff/loan-investment-product`);
};

export const getLoanInvestments = () => {
  return apiWithToken().get(`/loan_investor/staff/loan-investment`);
};

export const getSavingsProductById = id => {
  return apiWithToken().get(`/savings_products/${id}`);
};

export const getSavingsAccountById = id => {
  return apiWithToken().get(`/savings_wallets/staffs/${id}`);
};

export const getInvestmentAccountById = id => {
  return apiWithToken().get(`/investor_accounts/staffs/${id}`);
};

export const getSavingsFeeById = id => {
  return apiWithToken().get(`/savings_fees/${id}`);
};

export const getInvestmentFeeById = id => {
  return apiWithToken().get(`/investments_fees/${id}`);
};

export const getLoanFeesById = id => {
  return apiWithToken().get(`/loan_fees/${id}`);
};

export const getInvestmentProductById = id => {
  return apiWithToken().get(`/investment_products/${id}`);
};

export const getLoanInvestmentProductById = id => {
  return apiWithToken().get(
    `/loan_investor/staff/loan-investment-product/${id}`
  );
};

export const getAllPayroll = () => {
  return apiWithToken().get(`/payroll`);

  // return apiWithToken().get(`/payroll/dashboard/view`);
};

export const getPayrollDash = () => {
  return apiWithToken().get(`/payroll/dashboard/home`);

  // return apiWithToken().get(`/payroll/dashboard/view`);
};

export const getExpenseById = id => {
  return apiWithToken().get(`/expense/${id}`);
};

export const getSavingsProfileById = id => {
  return apiWithToken().get(`/savings_profiles/staffs/${id}`);
};

export const getInvestorProfileById = id => {
  return apiWithToken().get(`/investor_profiles/staffs/${id}`);
};

export const getBorrowerSavingsAccount = id => {
  return apiWithToken().get(`/savings_profiles/staffs/${id}`);
};

export const getSavingsAccountProfile = id => {
  return apiWithToken().get(`/savings_wallets/${id}/staffs`);
};

export const getInvestorById = id => {
  return apiWithToken().get(`/investor_profiles/staffs/${id}`);
};

export const getLoanTemplateById = id => {
  return apiWithToken().get(`/e_signatures/templates/${id}`);
};

export const sendLoanStatement = id => {
  return apiWithToken().get(`/loan/staff/send-loan-statement/${id}`);
};

export const deletePayslip = id => {
  return apiWithToken().delete(`/payroll/${id}`);
};

export const getPayslipById = id => {
  return apiWithToken().get(`/payroll/${id}`);
};

export const getMonthlyReport = () => {
  return apiWithToken().get(`/reports/monthly`);
};

export const getCollateralTypes = () => {
  return apiWithToken().get(`/collateral/type`);
};

export const getAllLoanTemplates = () => {
  return apiWithToken().get(`/e_signatures/templates`);
};

export const getInvestmentProducts = () => {
  return apiWithToken().get(`/investment_products`);
};

export const getAllESignatures = () => {
  return apiWithToken().get(`/e_signatures/documents`);
};

// -------------------------------------------------------------------

export const fetchBvnDetails = data => {
  return apiWithoutToken().post("/accounts/details", data);
};

export const forgotPassword = data => {
  return apiWithoutToken().post("/auth/forget_password", data);
};

export const sendDocumentForSigning = data => {
  return apiWithToken().post("/e_signatures/documents", data);
};

export const sendSmsToBorrowers = data => {
  return apiWithToken().post("/borrower/send-sms", data);
};

export const addSavingsProduct = data => {
  return apiWithToken().post("/savings_products", data);
};

export const transferToSavings = data => {
  return apiWithToken().post(
    "/savings_transactions/transfer_to_savings/staff_routes",
    data
  );
};

export const createInvestmentProduct = data => {
  return apiWithToken().post("/investment_products", data);
};

export const sendInviteToCustomer = data => {
  return apiWithToken().post("/customers/invite", data);
};

export const transferCollateral = data => {
  return apiWithToken().post("/collateral/transfar-collateral", data);
};

export const createPayrollStaff = data => {
  return apiWithToken().post("/payroll", data);
};

export const creditSavingsAccount = data => {
  return apiWithToken().post(
    "/savings_transactions/credit_wallet/staff_routes",
    data
  );
};

export const creditInvestmentWallet = data => {
  return apiWithToken().post("/investor_transaction/staff/credit_wallet", data);
};

export const addLienToSavingsAccount = data => {
  return apiWithToken().post("/savings_wallets/lien/staffs", data);
};

export const debitSavingsAccount = data => {
  return apiWithToken().post(
    "/savings_transactions/debit_wallet/staff_routes",
    data
  );
};

export const debitInvestorAccount = data => {
  return apiWithToken().post("/investor_transaction/staff/debit_wallet", data);
};

export const createLoanInvestmentProduct = data => {
  return apiWithToken().post(
    "/loan_investor/staff/loan-investment-product",
    data
  );
};

export const createSavingsProfile = data => {
  return apiWithToken().post("/savings_profiles/staffs", data);
};

export const createInvestorProfile = data => {
  return apiWithToken().post("/investor_profiles/staffs", data);
};

export const createInvestor = data => {
  return apiWithToken().post("/investor_profiles/staffs", data);
};

export const createSavingsAccount = data => {
  return apiWithToken().post("/savings_wallets/staffs", data);
};

export const createInvestmentAccount = data => {
  return apiWithToken().post("/investor_accounts/staffs", data);
};

export const addTemplateESignature = data => {
  return apiWithToken().post("/e_signatures/templates/docx", data);
};

export const sendEmailToBorrowers = data => {
  return apiWithToken().post("/borrower/send-email", data);
};

export const sendEmailtoInvestors = data => {
  return apiWithToken().post("/investor_profile/staffs/send-email", data);
};

export const sendSmsToInvestors = data => {
  return apiWithToken().post("/investor_profile/staffs/send-sms", data);
};

export const getBvnInfo = data => {
  return apiWithoutToken().post("/accounts/bvn", data);
};

export const addLoanComment = data => {
  return apiWithToken().post("/loan/comment", data);
};

export const addExpense = data => {
  return apiWithToken().post("/expense", data);
};

export const addSavingsFee = data => {
  return apiWithToken().post("/investments_fees", data);
};

export const addInvestmentFee = data => {
  return apiWithToken().post("/savings_fees", data);
};

export const addLoanFees = data => {
  return apiWithToken().post("/loan_fees", data);
};

export const addAsset = data => {
  return apiWithToken().post("/assets", data);
};

export const addAssetType = data => {
  return apiWithToken().post("/assets/asset-types", data);
};

export const addLoanInvestment = data => {
  return apiWithToken().post("/loan_investor/staff/loan-investment", data);
};

export const addIncomeTypes = data => {
  return apiWithToken().post("/other_incomes/other-income-types", data);
};

export const addOtherIncome = data => {
  return apiWithToken().post("/other_incomes", data);
};

// All update requests
export const updateCustomerDetails = data => {
  return apiWithToken().patch("/customers", data);
};

export const resetPassword = data => {
  return apiWithToken().patch("/auth/reset_password", data);
};

export const updateBorrowerGroup = (id, data) => {
  return apiWithToken().patch(`/borrower/staff/group-borrower/${id}`, data);
};

export const updateSavingsAccount = (id, data) => {
  return apiWithToken().patch(`/savings_wallets/staffs/${id}`, data);
};

export const updateInvestmentAccount = (id, data) => {
  return apiWithToken().patch(`/investor_accounts/staffs/${id}`, data);
};

export const getLoanStatement = (id, data) => {
  return apiWithToken().post(`/loan/staff/loan-statement/${id}`, data);
};

export const updateExpense = (id, data) => {
  return apiWithToken().patch(`/expense/${id}`, data);
};

export const updateSavingsProduct = (id, data) => {
  return apiWithToken().patch(`/savings_products/${id}`, data);
};

export const updateSavingsFees = (id, data) => {
  return apiWithToken().patch(`/savings_fees/${id}`, data);
};

export const updateInvestmentFees = (id, data) => {
  return apiWithToken().patch(`/investments_fees/${id}`, data);
};

export const updateLoanFee = (id, data) => {
  return apiWithToken().patch(`/loan_fees/${id}`, data);
};

export const updateAssetType = (id, data) => {
  return apiWithToken().patch(`/assets/asset-type/${id}`, data);
};

export const updateAsset = (id, data) => {
  return apiWithToken().patch(`/assets/single/${id}`, data);
};

export const updateInvestmentProduct = (id, data) => {
  return apiWithToken().patch(`/investment_products/${id}`, data);
};

export const updateLoanInvestmentProduct = (id, data) => {
  return apiWithToken().patch(
    `/loan_investor/staff/loan-investment-product/${id}`,
    data
  );
};

export const updateLoanTemplate = (id, data) => {
  return apiWithToken().patch(`/e_signatures/templates/docx/${id}`, data);
};

export const restructureLoanStaff = (id, data) => {
  return apiWithToken().patch(`/loan/staff/loan/${id}`, data);
};

export const updateCollateralType = (id, data) => {
  return apiWithToken().patch(`/collateral/type/${id}`, data);
};

export const editCollateral = (id, data) => {
  return apiWithToken().patch(`/collateral/staff/collateral/${id}`, data);
};

export const updateWorkingStatus = (id, data) => {
  return apiWithToken().patch(`/borrower/staff/working-status/${id}`, data);
};

export const updateCollateralStatus = (id, data) => {
  return apiWithToken().post(`/collateral/staff/take-action/${id}`, data);
};

export const updateSavingsProfile = (id, data) => {
  return apiWithToken().patch(`/savings_profiles/staffs/${id}`, data);
};

export const updateInvestorProfile = (id, data) => {
  return apiWithToken().patch(`/investor_profiles/staffs/${id}`, data);
};

export const updateInvestor = (id, data) => {
  return apiWithToken().patch(`/investor_profiles/staffs/${id}`, data);
};

export const updateBorrowerInformation = (id, data) => {
  return apiWithToken().patch(`/borrower/staff/${id}`, data);
};

export const updateStaffDetails = (id, data) => {
  return apiWithToken().patch(`/staffs/${id}`, data);
};

export const updatePaySlip = (id, data) => {
  return apiWithToken().patch(`/payroll/${id}`, data);
};

export const approveLoanStaff = id => {
  return apiWithToken().patch(`/loan/approve/${id}`);
};

export const declineLoanStaff = id => {
  return apiWithToken().patch(`/loan/decline/${id}`);
};

export const updateBorrowerPhotoCustomer = (id, data) => {
  return apiWithToken().patch(`borrower/image-upload/${id}`, data);
};

export const updateCustomerDetailsStaff = (id, data) => {
  return apiWithToken().patch(`/customers/${id}`, data);
};

// All create requests
export const createBorrowerBVNCustomer = data => {
  return apiWithToken().post("/borrower/bvn-registration", data);
};

export const addCollateralType = data => {
  return apiWithToken().post("/collateral/type", data);
};

export const createLoanProduct = data => {
  return apiWithToken().post("/loan/loan-product", data);
};

export const createStaffStaff = data => {
  return apiWithToken().post("/staffs", data);
};

export const updateLoanProduct = (id, data) => {
  return apiWithToken().patch(`/loan/loan-product/${id}`, data);
};

export const deleteStaff = id => {
  return apiWithToken().delete(`/staffs/${id}`);
};

export const deleteExpense = id => {
  return apiWithToken().delete(`/expense/${id}`);
};

export const deleteSavingsProduct = id => {
  return apiWithToken().delete(`/savings_products/${id}`);
};

export const deleteSavingsFee = id => {
  return apiWithToken().delete(`/savings_fees/${id}`);
};

export const deleteInvestmentFee = id => {
  return apiWithToken().delete(`/investments_fees/${id}`);
};

export const deleteLoanFee = id => {
  return apiWithToken().delete(`/loan_fees/${id}`);
};

export const deleteAssetType = id => {
  return apiWithToken().delete(`/assets/asset-type/${id}`);
};

export const deleteIncomeType = id => {
  return apiWithToken().delete(`/other_incomes/other-income-type/${id}`);
};

export const deleteAsset = id => {
  return apiWithToken().delete(`/assets/single/${id}`);
};

export const deleteSavingsAccount = id => {
  return apiWithToken().delete(`/savings_wallets/staffs/${id}`);
};

export const deleteInvestorAccount = id => {
  return apiWithToken().delete(`/investor_accounts/staffs/${id}`);
};

export const deleteInvestmentProduct = id => {
  return apiWithToken().delete(`/investment_products/${id}`);
};

export const deleteLoanInvestmentProduct = id => {
  return apiWithToken().delete(
    `/loan_investor/staff/loan-investment-product/${id}`
  );
};

export const inviteStaffToOdiopay = data => {
  return apiWithToken().post(`/staffs/invite`, data);
};

export const createCustomerStaff = data => {
  return apiWithToken().post("/customers", data);
};

export const assignLoanToAStaff = data => {
  return apiWithToken().patch("/loan/assign/loan_officer", data);
};

export const disburseLoanManually = data => {
  return apiWithToken().post("/disburses/manual", data);
};

export const makeRepaymentStaff = data => {
  return apiWithToken().post("/repayments/staff/make-repayment", data);
};

export const makeRepaymentCustomer = data => {
  return apiWithToken().post("/repayments/make-repayment", data);
};

export const disburseLoanViaPaystack = data => {
  return apiWithToken().post("/disburses/auto", data);
};

export const createBorrowerGroup = data => {
  return apiWithToken().post("/borrower/staff/group-borrower", data);
};

export const createLoanCustomer = data => {
  return apiWithToken().post("/loan", data);
};

export const getDueLoans = data => {
  return apiWithToken().post("/loan/filter/due-loan", data);
};

export const createBranchStaff = data => {
  return apiWithToken().post("/branch", data);
};

export const calculateLoan = data => {
  return apiWithToken().post("/repayments/calculator", data);
};

export const createLoanStaff = data => {
  return apiWithToken().post("/loan/staff/loan", data);
};

export const createRole = data => {
  return apiWithToken().post("/roles", data);
};

export const createGuarantorCustomer = data => {
  return apiWithToken().post("/guarantor/borrower_access", data);
};

export const createGuarantorStaff = data => {
  return apiWithToken().post("/guarantor/staff_access", data);
};

export const addBankStatementStaff = data => {
  return apiWithToken().post("/bankstatement/staff/image", data);
};

export const addCollateralFile = data => {
  return apiWithToken().post("/collateral/collateral-file/staff", data);
};

export const addCollateral = data => {
  return apiWithToken().post("/collateral/staff/collateral", data);
};

export const createBorrowerManuallyStaff = data => {
  return apiWithToken().post("/borrower/staff/borrower", data);
};

export const createBorrowerWorkingStatusStaff = data => {
  return apiWithToken().post("/borrower/staff/working-status", data);
};

export const createWorkingStatusCustomer = data => {
  return apiWithToken().post("/borrower/working-status", data);
};
