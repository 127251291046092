export const config = {
  base_url: "https://loanx.com.ng",
  token_name: "LOAN_TOKEN"
};

export const appConfig = {
  // base_url: "https://loanx-api.herokuapp.com/api/v1",
  // base_url: "https://loanx-odio.herokuapp.com/api/v1",
  // base_url: "https://www.odio.com.ng/api/v1",
  // base_url: "https://odio.com.ng/api/v1/",
  base_url: "https://api.dizbuzz.com/api/v1/",
  token_name: "LOAN_TOKEN",
  token_name2: "LOAN_CWS_TKN"
};

// ("https://api.loanx.dacoding.com/api/v1");
