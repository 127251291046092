import axios from "axios";

import { config as app_config } from "./constants.js";

function api(token) {
  const config = { baseURL: app_config.base_url };
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  const instance = axios.create(config);
  return instance;
}

function api2(token) {
  const config = { baseURL: "https://digitalbank.dizbuzz.com" };
  if (token) {
    config.headers = { Authorization: `JWT ${token}` };
  }
  const instance = axios.create(config);
  return instance;
}

export function remittaGet(phone, id) {
  return api().get(`/api/remita/salary/?borrower_mobile=${phone}&loan=${id}`);
}

export function login(email, password) {
  const req = {
    username: email,
    password
  };
  return api().post("api-token-auth/", req);
}

export function Remitta(url, data) {
  return api().post(url, data);
}

export function loanScheduleCalculator(data) {
  return api().post("/loan/calculator/", data);
}
// export function getAndPersistUser(token) {
// 	return api(token).get("/api/user/");
// }

export function getUsers({
  user_type = "",
  user_name = "",
  first_name = "",
  last_name = ""
}) {
  let url = "api/user-profile";
  const params = {};
  if (!!user_type) {
    params["user_type"] = user_type;
  }
  if (!!user_name) {
    params["username"] = user_name;
  }
  if (!!first_name) {
    params["first_name"] = first_name;
  }
  if (!!last_name) {
    params["last_name"] = last_name;
  }
  const url_params = new URLSearchParams(params);
  url += "?" + url_params.toString();
  return api().get(url);
}

export function getGeneralApi(url) {
  return api().get(url);
}
export function getCountry() {
  return api().get("/api/country");
}
export function viewCusProfile(id) {
  return api().get(`/api/user-profile/${id}/`);
}

export function generalPost(url, data) {
  return api().post(url, data);
}

export function approverLoanRep(id, data) {
  return api().patch(`/api/approve-or-decline-repayment/${id}/`, data);
}

export function pageWithSearchText(search = "", url, additionalParam) {
  let uurl = url;
  if (!!search) {
    uurl += `${additionalParam}${search}`;
  }

  return api().get(uurl);
}
export function pageWithSearchNumber(search = 0, url, additionalParam) {
  let uurl = url;
  if (!!search) {
    uurl = `${additionalParam}${search}`;
  }

  return api().get(uurl);
}

export function getBorrowers(search = "") {
  let url = "/borrowers/api";
  if (!!search) {
    url += "?borrower_search=" + search;
  }
  return api().get(url);
}

export function getStaff(search = "") {
  let url = "/staffs/api/";
  if (!!search) {
    url += "?staff_search=" + search;
  }
  return api().get(url);
}

export function getAllBranches() {
  return api().get("/api/branch/");
}

export function createUserAccount(user_data) {
  return api().post("api/accounts/", user_data);
}

export function createBorrowerGroup(data) {
  return api().post("/borrowers/api/group/", data);
}

export function updateBorrowerGroup(id, data) {
  return api().patch(`borrowers/api/group/${id}/`, data);
}

export function getBorrowersGroup() {
  return api().get("/borrowers/api/group/");
}

export function searchBorrowersGroup(search) {
  return api().get("/search_borrower_group?ref=" + search);
}

export function bvnCheck(profile, bvn, date) {
  return api().post("/api/bvn_check/", { profile, bvn, dob: date });
}

export function createBorrower(data) {
  return api().post("/borrowers/api/", data);
}
export function createCusGuarantor(data) {
  return api().post("/api/loan-guarantor/", data);
}

export function updateBorrowerData(id, data) {
  return api().patch(`/borrowers/api/${id}/`, data);
}
export function updateCusGuarantorData(id, data) {
  return api().patch(`/api/loan-guarantor/${id}/`, data);
}
export function getBorrowerById(id) {
  return api().get(`/borrowers/api/${id}/`);
}
export function getCusLoanById(id) {
  return api().get(`/loans/?borrower=${id}`);
}

export function getCreditScore(phone, borrower) {
  return api().post("/api/get_loan_score", { phone, borrower });
}

export function getLoanType() {
  return api().get("/api/loan-type");
}

export function createLoan(data) {
  return api().post("/loans/", data);
}
export function getCustomerLoan(id) {
  return api().get(`/loans/?borrower=${id}`);
}
export function getUserRecord() {
  const localToken = localStorage.getItem("LOAN_TOKEN");
  return api2(localToken).get(`/loan/paid-results`);
}
export function createLoanCustomer(data) {
  const localToken = localStorage.getItem("LOAN_TOKEN");
  return api2(localToken).post("/loan/apply/", data);
}

export function uploadLoanDocument(loan, title, attachment) {
  const formData = new FormData();
  formData.append("loan", loan);
  formData.append("name", title);
  formData.append("attachment", attachment, attachment.name);
  return api().post("/loan_attachment/", formData);
}
function parseParams(search) {
  const params = {};
  search = JSON.parse(search);
  if (search && search.length) {
    search.forEach(s => {
      if (!!s.searchTerm) {
        params[s.searchType] = s.searchTerm;
      }
    });
  }
  const url_params = new URLSearchParams(params);
  return "?" + url_params.toString();
}
export function getDueLoans(search) {
  let url = "due_loans_between/";
  url += parseParams(search);
  return api().get(url);
}

export function getLoans(search) {
  let url = "/loans/";
  console.log("df", search);
  url += parseParams(search);
  console.log(url);
  return api().get(url);
}

export function getLoanRepayment(search) {
  let url = "/api/loan-repayment/";
  if (!!search) {
    url += "?borrower=" + search;
  }
  return api().get(url);
}

export function getAccountName(data) {
  return api().post("api/get_account_name/", data);
}

export function getLoanOfficer() {
  return api().get("/loan_officers/");
}

export function createDisbursement(data) {
  return api().post("/api/loan-disbursement/", data);
}

export function fetchDisbursementHistory() {
  return api().get("/api/loan-disbursement/");
}

export function makeRepayment(data) {
  return api().post("/api/loan-repayment/", data);
}

export function addMemberToGroup(borrower, borrower_group) {
  return api().post("borrowers/api/add-to-group/", {
    borrower,
    borrower_group
  });
}

export function createLoanType(data) {
  return api().post("api/loan-type/", data);
}

export function updateLoanType(id, data) {
  return api().patch(`api/loan-type/${id}/`, data);
}

export function deleteLoanType(id) {
  return api().delete(`api/loan-type/${id}/`);
}

export function deleteBranch(id) {
  return api().delete(`api/branch/${id}/`);
}

export function createCountry(data) {
  return api().post("/api/country/", data);
}

export function fetchCountry() {
  return api().get("api/country/");
}

export function updateCountry(id, data) {
  return api().patch(`api/country/${id}/`, data);
}

export function deleteCountry(id) {
  return api().delete(`api/country/${id}/`);
}

export function getLoanGuarantor() {
  return api().get("api/loan-guarantor/");
}

export function deleteGuarantor(id) {
  return api().delete(`api/loan-guarantor/${id}/`);
}

export function uploadGuarantorFile(g, file) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("guarantor", g);

  return api().post("api/loan-guarantor-file/", formData);
}

export function guarantorDetails(id) {
  return api().get(`/api/loan-guarantor/${id}/`);
}

export function deleteGuarantorFile(guarantor_id, file_id) {
  return api().delete(`api/loan-guarantor-file/${file_id}/`);
}

export function createBranch(data) {
  return api().post("api/branch/", data);
}

export function updateBranch(id, data) {
  return api().patch(`api/branch/${id}/`, data);
}

export function suspendUser(id, suspend) {
  return api().patch(`api/suspend-user/${id}/`, { suspend });
}

export function deleteBorrower(id) {
  return api().delete(`borrowers/api/${id}/`);
}

export function deleteBorrowerGroup(id) {
  return api().delete(`borrowers/api/group/${id}/`);
}

export function approveOrDeclineLoan(
  loan,
  action,
  staff,
  amount,
  dur,
  ldp,
  lipp,
  lip,
  rc,
  nor
) {
  let data;
  if (amount !== null) {
    data = {
      loan,
      decision: action,
      amount,
      duration: dur,
      loan_duration_period: ldp,
      loan_interest_percentage_period: lipp,
      loan_interest_percentage: lip,
      repayment_cycles: rc,
      no_of_repayments: nor,
      staff: staff
    };
  } else if (amount === null) {
    data = {
      loan,
      decision: action,
      staff: staff
    };
  }
  console.log(data);
  return api().post("/api/approve-or-decline-loan/", data);
}

export function createGuarantor(data) {
  return api().post("api/loan-guarantor/", data);
}

export function updateGuarantor(id, data) {
  return api().patch(`api/loan-guarantor/${id}/`, data);
}

export function dueLoanNoPayment() {
  return api().get("due_loans_no_payment/");
}
export function dueLoanPartPayment() {
  return api().get("due_loans_part_payment/");
}

export function getPrincipalOutstanding() {
  return api().get("principal_outstanding_loans/");
}
export function getBorrowerReport() {
  return api().get("/api/borrowers_report/");
}
export function getLoanReport() {
  return api().get("/api/loans_report/");
}
export function getCollectionReport() {
  return api().get("/api/collection_report/");
}
export function getLoanOfficerReport() {
  return api().get("/api/loan_officer_report/");
}
export function getLoanProductReport() {
  return api().get("/api/loan_product_report/");
}
export function getAllEntriesReport() {
  return api().get("/api/all_enteries/");
}
export function getDisbursementReport() {
  return api().get("/api/disbursement_report/");
}
export function getOutstandingReport() {
  return api().get("/api/outstanding_report/?date=2020-07-17");
}
export function getAtAGlanceReport() {
  return api().get("/api/at_a_glance_report/");
}

export function getLoanDocuments(loan) {
  return api().get("/loan_attachment/?loan=" + loan);
}
export function getLoansInArrears(search) {
  return api().get("due_loans_by_days/?days_due=" + search);
}

export function getLoanComments(search) {
  let url = "loan_comments/";
  parseParams(search);
  return api().get(url);
}
export function createLoanComments(data) {
  return api().post("loan_comments/", data);
}

export function getBorrowerGuarantors(borrower_id) {
  return api().get("api/loan-guarantor/?borrower=" + borrower_id);
}

export function fetchLoanFees() {
  return api().get("api/loan-fees/");
}
export function getMonthlyReport() {
  return api().get("api/monthly_report/?branch=1");
}
export function createLoanFees(data) {
  return api().post("api/loan-fees/", data);
}
export function updateLoanFees(id, data) {
  return api().patch(`/api/loan-fees/${id}/`, data);
}

export function deleteLoanFees(id) {
  return api().delete(`/api/loan-fees/${id}/`);
}

export function fetchCollateral(search) {
  let url = "api/loan-collateral/";
  url += parseParams(search);
  return api().get(url);
}
export function createCollateral(data) {
  return api().post("api/loan-collateral/", data);
}

export function deleteCollateral(id) {
  return api().delete(`api/loan-collateral/${id}/`);
}

export function fetchLoanSummary() {
  return api().get("api/summary");
}
