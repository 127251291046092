import React, { useState } from "react";

import { Link } from "react-router-dom";
import { ExtractUserContext } from "../../context/app_context";
import { useLogin } from "../../hooks.js/useLogin";

import { appConfig } from "../../utils/constants";
import { storageContainsToken } from "../../utils/functions";

export function Login() {
  // if(storageContainsToken)

  const {
    formData,
    isSubmitting,
    errors,
    setUserObject,
    handleInputChange,
    handleFormSubmit
  } = useLogin();

  return (
    <div className="bg-blue-1 md:h-screen w-screen">
      <div className="container flex flex-col">
        <nav className="flex items-center text-white pt-5">
          {/* <h1 className="text-3xl font-medium">Dizbuzz</h1>
           */}
          <img
            alt={""}
            className="w-20 object-contain"
            src={require("./../../assets/Logo-Sinle-colour.png")}
          />
          <div className="ml-auto text-xs font-normal">
            <span className="">New users? </span>
            <Link to="/register" className="ml-2 text-pink-1">
              Create Account
            </Link>
          </div>
        </nav>
        <div className="flex-1 mt-5 md:mt-20 flex flex-col md:flex-row md:items-center h-full">
          <div className="md:w-7/12 mb-10 md:mb-0">
            <h3 className="text-4xl font-bold text-white ">
              we dizbuzz quickly!
            </h3>
          </div>
          <div className="md:mr-5 md:ml-8 text-white">
            <h5 className="text-base font-semibold">Sign In</h5>
            <p className="text-xs mt-2 mb-6">
              Enter your details to login to your dashboard
            </p>
            {/* {!!error && (
							<span className="text-red-600 block mb-2 font-normal">
								{error}
							</span>
						)} */}
            <form
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <div className="bg-white bg-opacity-25 border rounded-full flex items-center p-2 h-10">
                <img
                  alt=""
                  className="pl-1 pr-2"
                  src={require("./../../assets/login icon.svg")}
                />
                <input
                  className="bg-transparent flex-1"
                  autoFocus
                  name="email"
                  type="text"
                  placeholder="Enter Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              {errors.email && (
                <span className="text-red-600 block mb-2 font-normal">
                  {errors.email}
                </span>
              )}
              <div className="mt-3 mb-5 bg-white bg-opacity-25 border rounded-full flex items-center p-2 h-10">
                <img
                  alt=""
                  className="pl-1 pr-2"
                  src={require("./../../assets/login-password-icon.svg")}
                />
                <input
                  className="bg-transparent flex-1"
                  autoFocus
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="Enter Password"
                  required
                />
              </div>
              {errors.password && (
                <span className="text-red-600 block mb-2 font-normal">
                  {errors.password}
                </span>
              )}
              <div>
                <button
                  onClick={() => {
                    handleFormSubmit();
                  }}
                  type="submit"
                  className="bg-white focus:outline-none shadow px-16 text-gray-800 font-semibold py-3 rounded-full"
                >
                  {isSubmitting ? (
                    <span className="spinner-spin h-6 w-6"></span>
                  ) : (
                    "Login"
                  )}
                </button>
                <Link
                  to="/forgot-password"
                  className="font-normal block text-xs my-3"
                >
                  Forgot password?
                </Link>
                <p className="font-normal text-xs my-3">
                  <span>Not Registered? </span>
                  <Link
                    to="/register"
                    className="text-pink-1 font-medium text-xs"
                  >
                    Sign Up Now
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
